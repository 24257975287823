import { graphql } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/layout";
import * as styles from "../styles/shops.module.scss";
import { Map } from "../components/Map/map";
import { BurgerMenu } from "../components/Header/burgerMenu";
import GoBack from "../components/Utils/backButton";
import GoHome from "../components/Utils/homeButton";
import { usePageContext } from "../components/Context/pageContext";
import SEO from "../components/seo";

interface Props {
  data: any;
}

const shops: React.FC<Props> = ({ data }) => {
  const { shops, settings, seo } = data;
  const [filter, setFilter] = useState<string>("");
  const [input, setInput] = useState<string>("");
  const [panCoords, setPanCoords] = useState<any>(undefined);
  const [geoloc, setGeoloc] = useState<any>(undefined);

  const { lang } = usePageContext();
  const handleAroundme = () => {
    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        setPanCoords({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          bool: !panCoords?.bool || true,
        });
      },
      (err) => console.log(`error`, err)
      // { timeout: 20000, enableHighAccuracy: true }
    );
  };

  const filteredShops = shops.edges.filter((shop: any) =>
    shop.node.zipCode.startsWith(filter)
  );

  const bounds = filteredShops.map((shop: any) => ({
    lat: shop.node.coords.lat,
    lng: shop.node.coords.lng,
  }));

  const coords = shops.edges.map((shop: any) => ({
    lat: shop.node.coords.lat,
    lng: shop.node.coords.lng,
    name: shop.node.name,
  }));

  const handleClick = () => {
    const element = document.getElementById("navvMenu");
    if (element) element.classList.toggle("inactive");
  };

  return (
    <Layout theme="light" withoutLogo={true}>
      <SEO
        title={seo?.shops?.title && seo?.shops?.title[lang]}
        description={seo?.shops?.desc && seo?.shops?.desc[lang]}
        image={seo?.shops?.image?.asset?.url}
      />

      <div className={styles.pageWrapper}>
        <div className={`margin ${styles.pageTitleWrapper}`}>
          <GoBack />
          <div className="btitle">
            <GoHome />
            <div className={styles.pageTitle}>{settings.pageTitle[lang]}</div>
            <div id="burgerMenu" className="burgerPosition">
              <BurgerMenu theme={"light"} onClick={handleClick} />
            </div>
          </div>
        </div>
        <div className={styles.shopsContent}>
          <div className={styles.mapWrapper}>
            <Map
              coords={coords}
              panCoords={panCoords}
              boundsCoords={bounds.length ? bounds : coords}
              myPos={geoloc}
            />
            <button
              className={`${styles.aroundmeWrapper} noStyle`}
              onClick={handleAroundme}
            >
              <img src="/aroundme.svg" alt="xorus aroundme" />
            </button>
          </div>
          <div className={styles.filterWrapper}>
            <form
              name="filterForm"
              method="post"
              className={styles.formWrapper}
              onSubmit={(e: any) => {
                e.preventDefault();
                // const form = e.target;
                // const data = new FormData(form);
                setPanCoords(undefined);
                setFilter(input);
                // setPanCoords(undefined);
              }}
            >
              <div className={styles.inputWrapper}>
                <input
                  type="text"
                  aria-label="Zip code"
                  placeholder={settings.placeholder[lang]}
                  name="searchFilter"
                  autoComplete="off"
                  className={styles.filterInput}
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                />
                {input.length > 0 && (
                  <div className={styles.clearWrapper}>
                    <span
                      onClick={() => {
                        setInput("");
                        setFilter("");
                      }}
                    >
                      &times;
                    </span>
                  </div>
                )}
              </div>
              <div style={{ display: "flex", margin: "0 auto" }}>
                <button type="submit" className={styles.filterButton}>
                  {settings.search[lang]}
                </button>
              </div>
            </form>
            <div className={styles.shopList}>
              {filteredShops.length ? (
                filteredShops.map((shop: any, index: number) => (
                  <div
                    className={styles.shopContainer}
                    onClick={() =>
                      setPanCoords({
                        lat: shop.node.coords.lat,
                        lng: shop.node.coords.lng,
                      })
                    }
                  >
                    <div className={styles.shopTitle}>{shop.node.name}</div>
                    <div className={styles.shopInfo}>{shop.node.address}</div>
                    {shop.node.phoneNumber && (
                      <div
                        className={styles.shopInfo}
                      >{`Phone: ${shop.node.phoneNumber}`}</div>
                    )}
                    {shop.node.url && (
                      <div
                        className={styles.shopInfo}
                      >{`web: ${shop.node.url}`}</div>
                    )}
                  </div>
                ))
              ) : (
                <div className={styles.shopInfo}>
                  {settings.errorMessage[lang]}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default shops;

export const query = graphql`
  query {
    shops: allSanityShop {
      edges {
        node {
          id
          name
          address
          url
          phoneNumber
          zipCode
          coords {
            lat
            lng
          }
        }
      }
    }
    settings: sanityShopPage {
      search {
        en
        es
      }
      placeholder {
        en
        es
      }
      pageTitle {
        en
        es
      }
      errorMessage {
        en
        es
      }
    }
    seo: sanitySiteSettings {
      shops {
        desc: seoDescription {
          en
          es
        }
        image: seoImage {
          asset {
            url
          }
        }
        title: seoTitle {
          en
          es
        }
      }
    }
  }
`;
