// extracted by mini-css-extract-plugin
export const pageWrapper = "shops-module--pageWrapper--20_hx";
export const formWrapper = "shops-module--formWrapper--27XJ2";
export const pageTitleWrapper = "shops-module--pageTitleWrapper--1LGhW";
export const pageTitle = "shops-module--pageTitle--2iXYZ";
export const shopsContent = "shops-module--shopsContent--1E4sy";
export const mapWrapper = "shops-module--mapWrapper--CS3Qg";
export const filterWrapper = "shops-module--filterWrapper--SrG0Q";
export const backButton = "shops-module--backButton--34jxC";
export const flexColumn = "shops-module--flexColumn--1DP2C";
export const inputWrapper = "shops-module--inputWrapper--3R_JQ";
export const clearWrapper = "shops-module--clearWrapper--xXYAb";
export const filterInput = "shops-module--filterInput--1kNGY";
export const filterButton = "shops-module--filterButton--9tzOn";
export const shopList = "shops-module--shopList--8hpGh";
export const shopTitle = "shops-module--shopTitle--32L4A";
export const shopInfo = "shops-module--shopInfo--3Bg7A";
export const shopContainer = "shops-module--shopContainer--3S9C4";
export const customClustericon1 = "shops-module--custom-clustericon-1--2c-14";
export const customClustericon2 = "shops-module--custom-clustericon-2--LypNg";
export const customClustericon3 = "shops-module--custom-clustericon-3--3Tqmc";
export const aroundmeWrapper = "shops-module--aroundmeWrapper--232nZ";