import React from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import * as styles from "../../styles/map.module.scss";
import { mapStyles } from "../../styles/MapStyles";
import { clusterStyles } from "../../styles/ClusterStyles";
import { SetBounds } from "./setBounds";
import { PanTo } from "./panTo";
// import XorusPin from "../../../static/Xorus_try2.svg";

interface Props {
  coords: any;
  panCoords: any;
  boundsCoords: any;
  myPos?: any;
}

const MapComponent: React.FC<Props> = ({
  coords,
  panCoords,
  boundsCoords,
  myPos,
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBBmyP4NoEqD1RsF7Kkxir4uWX-_nqJnYA",
    // ...otherOptions
  });

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that

    return (
      <GoogleMap
        onLoad={(mapInstance) => {
          const bounds = new window.google.maps.LatLngBounds();
          boundsCoords &&
            boundsCoords.map((item: any) => {
              // locations.map((item: any) => {
              bounds.extend(item);
            });
          mapInstance.fitBounds(bounds);
        }}
        zoom={4}
        options={{
          panControl: true,
          streetViewControl: false,
          mapTypeControl: false,
          scrollwheel: false,
          zoomControl: true,
          fullscreenControl: false,
          styles: mapStyles,
          backgroundColor: "#202023",
          maxZoom: 15,
        }}
        mapContainerStyle={{
          height: "593px",
        }}
      >
        {!panCoords && <SetBounds coords={boundsCoords} />}
        {/* <SetBounds coords={boundsCoords} /> */}
        {panCoords && (
          <>
            <SetBounds coords={[panCoords]} />
            <PanTo panCoords={panCoords} />
          </>
        )}
        {coords && (
          <>
            <MarkerClusterer
              styles={clusterStyles}
              clusterClass="custom-clustericon"
            >
              {(clusterer) =>
                coords &&
                coords.map((location: any) => (
                  //   locations.map((location: any) => (
                  <Marker
                    key={createKey(location)}
                    position={location}
                    label={{
                      text: location.name,
                      color: "#eedd24",
                      className: `${styles.label}`,
                    }}
                    clusterer={clusterer}
                    icon="/Xorus_try2.svg"
                  />
                ))
              }
            </MarkerClusterer>
            {myPos && (
              <Marker
                key={createKey(myPos)}
                position={myPos}
                icon="/PictoGeoloc.svg"
              />
            )}
          </>
        )}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : null;
};

export const Map = MapComponent;

function createKey(location: any) {
  return location.lat + location.lng;
}
//// pour test
const locations = [
  { lat: -31.56391, lng: 147.154312 },
  { lat: -33.718234, lng: 150.363181 },
  { lat: -33.727111, lng: 150.371124 },
  { lat: -33.848588, lng: 151.209834 },
  { lat: -33.851702, lng: 151.216968 },
  { lat: -34.671264, lng: 150.863657 },
  { lat: -35.304724, lng: 148.662905 },
  { lat: -36.817685, lng: 175.699196 },
  { lat: -36.828611, lng: 175.790222 },
  { lat: -37.75, lng: 145.116667 },
  { lat: -37.759859, lng: 145.128708 },
  { lat: -37.765015, lng: 145.133858 },
  { lat: -37.770104, lng: 145.143299 },
  { lat: -37.7737, lng: 145.145187 },
  { lat: -37.774785, lng: 145.137978 },
  { lat: -37.819616, lng: 144.968119 },
  { lat: -38.330766, lng: 144.695692 },
  { lat: -39.927193, lng: 175.053218 },
  { lat: -41.330162, lng: 174.865694 },
  { lat: -42.734358, lng: 147.439506 },
  { lat: -42.734358, lng: 147.501315 },
  { lat: -42.735258, lng: 147.438 },
  { lat: -43.999792, lng: 170.463352 },
];
