export const clusterStyles =[
  {
    width: 30,
    height: 30,
    textSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    fontFamily: "Roboto",
    url: ""
  },
  {
    width: 40,
    height: 40,
    textSize: 18,
    fontWeight: "normal",
    fontStyle: "normal",
    fontFamily: "Roboto",
    url: ""
  },
  {
    width: 50,
    height: 50,
    textSize: 20,
    fontWeight: "normal",
    fontStyle: "normal",
    fontFamily: "Roboto",
    url: ""
  },
];
